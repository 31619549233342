import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from 'reactstrap';
import { EmptyState, Error, Loader, Modal, Pagination } from '@components';
import { ConferenceContext, paginationContext, ThemeContext } from '@context';
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import {
  ConferencesService,
  ConferencesState,
  ConferencesSuccessState,
  ErrorState,
  LoadingState,
  PaginationService,
  PaginationState,
  SubjectState,
  ThemeService,
  ThemeState,
  useService,
} from '@services';
import { ongoingConferenceTableHeaders, pastConferenceTableHeaders } from '@static';
import moment from 'moment';
import queryString, { ParsedQuery } from 'query-string';
import { ConferencesItem } from './ConferencesItem';

export const ConferenceDetails = (): ReactElement => {
  const [state, service] = useService<ConferencesState, ConferencesService>(ConferenceContext);
  const [, paginationService] = useService<PaginationState, PaginationService>(paginationContext);
  const [, themeService] = useService<ThemeState, ThemeService>(ThemeContext);

  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams: ParsedQuery<string | number> = queryString.parse(search);
  const {
    appType,
    callType,
    status,
    pageIndex,
    start,
    end,
    timezone,
    searchVal,
    searchBy,
    organization,
    isUsingMediaServer,
    clientType,
    endReason,
    participantLength,
  } = queryParams;
  const isSuccessState = state instanceof ConferencesSuccessState;
  const isError = state instanceof ErrorState;
  const isLoading = state instanceof LoadingState;
  const isEmptyState = !isLoading && !(state instanceof ErrorState) && state instanceof ConferencesSuccessState && state.conferences.conferences.length === 0;
  const isDarkMode = themeService.theme.value === 'dark';

  const handlePageChange = (pageSize: number, index: number) => {
    queryParams.pageIndex = String(index);
    service.pageIndex.next(index);
    paginationService.pageSize.next(pageSize);
    navigate({
      search: queryString.stringify({ ...(!queryParams ? { pageIndex: service.pageIndex.value } : { ...queryParams }) }),
    });
  };
  const handleCloseErrorModal = () => service.terminateConferenceMessage.next('');
  const handleShowModal = (conferenceId: string) => {
    service.isTerminating.next(true);
    service.conferenceId.next(conferenceId);
  };
  const handleCloseTerminatedModal = () => {
    service.collect().subscribe((conferencesState: ConferencesState) => service.next(conferencesState));
    service.isTerminated.next(false);
  };
  const handleCloseModal = () => service.isTerminating.next(false);
  const handleTerminate = () => service.terminateConferenceCall();
  const handleGoToConference = (id: string) => () => {
    navigate(`/conferences/${service.isOngoing ? 'ongoing' : 'past'}/${id}`);
  };
  const getSearchByLabel = (searchByValue: number) => {
    switch (searchByValue) {
      case 0:
        return 'Participant Name';
      case 1:
        return 'Conference Id';
      case 2:
        return 'Device Serial Number';
      case 3:
        return 'Object Id';
      default:
        return '';
    }
  };

  const handleSetParams = () => {
    service.appType.next(appType ? Number(appType?.toString()) || 'All' : 'All');
    service.callType.next(callType ? Number(callType?.toString()) || 'All' : 'All');
    service.status.next(status ? Number(status?.toString()) || 'All' : 'All');
    service.start.next(start ? moment(Number(start?.toString()) * 1000) : moment().startOf('days'));
    service.end.next(end ? moment(Number(end?.toString()) * 1000) : moment().endOf('days'));
    service.timezone.next(timezone?.toString() || '');
    service.searchValue.next(searchVal ? searchVal?.toString() || '' : '');
    service.searchBy.next(
      searchBy ? { value: Number(searchBy?.toString()), label: getSearchByLabel(Number(searchBy?.toString())) } : { value: 1, label: 'Conference Id' }
    );
    service.selectedOrganization.next(organization ? organization?.toString() || 'All' : 'All');
    service.participantLength.next(participantLength && participantLength !== 0 ? Number(participantLength) : null);
    service.clientType.next(clientType ? Number(clientType) : 'All');
    service.endReason.next(endReason && endReason !== 'All' ? Number(endReason) : 'All');
    service.isUsingMediaServer.next(isUsingMediaServer ? isUsingMediaServer?.toString() === 'true' ?? false : null);
    service.pageIndex.next(pageIndex ? Number(pageIndex?.toString() || 0) : 0);
  };

  useEffect(() => {
    if (
      appType ||
      callType ||
      status ||
      pageIndex ||
      start ||
      end ||
      timezone ||
      searchVal ||
      searchBy ||
      organization ||
      isUsingMediaServer ||
      clientType ||
      endReason ||
      participantLength
    ) {
      handleSetParams();
      navigate({
        search: queryString.stringify({
          appType: service.appType.value,
          callType: service.callType.value,
          status: service.status.value,
          searchVal: service.searchValue.value,
          searchBy: service.searchBy.value.value,
          pageIndex: service.pageIndex.value,
          start: service.start.value.unix(),
          end: service.end.value.unix(),
          organization: service.selectedOrganization.value,
          endReason: service.endReason.value === 0 ? 'All' : service.endReason.value,
          ...(service.timezone.value && { timezone: service.timezone.value }),
          ...(service.isUsingMediaServer.value !== null ? { isUsingMediaServer: service.isUsingMediaServer.value } : {}),
          ...(service.clientType.value !== 'All' ? { clientType: service.clientType.value } : {}),
          ...(service.participantLength.value !== null ? { participantLength: service.participantLength.value } : {}),
        }),
      });
    }
  }, [
    appType,
    callType,
    status,
    pageIndex,
    start,
    end,
    timezone,
    searchVal,
    searchBy,
    organization,
    clientType,
    endReason,
    isUsingMediaServer,
    participantLength,
  ]);

  return (
    <>
      <Table className='mt-3' hover={!isDarkMode} striped responsive>
        <thead className='dark:text-gray-400 dark:border-gray-700'>
          <tr>
            {service.isOngoing
              ? ongoingConferenceTableHeaders.map(header => (
                  <th className='text-sm text-left dark:text-[#989082]' key={header}>
                    {header}
                  </th>
                ))
              : pastConferenceTableHeaders.map(header => (
                  <th className='text-sm text-left dark:text-[#989082]' key={header}>
                    {header}
                  </th>
                ))}
          </tr>
        </thead>
        <tbody className='dark:border-gray-700'>
          {!isSuccessState && !isLoading && !isError && (
            <tr>
              <td style={{ padding: 16 }} className='text-center text-sm' colSpan={14}>
                Apply any filter to list conferences.
              </td>
            </tr>
          )}
          {isEmptyState && (
            <EmptyState className='text-center w-full' as='tr'>
              <td className='bg-[#fff] dark:bg-dark' colSpan={14}>
                <NewspaperIcon className='text-gray-600 dark:text-gray-300 w-10 h-10 mx-auto' />
                <span className='font-medium dark:text-gray-300'>No data to be shown.</span>
              </td>
            </EmptyState>
          )}
          {isLoading && (
            <tr>
              <td colSpan={15}>
                <Loader />
              </td>
            </tr>
          )}
          {state instanceof ErrorState && (
            <tr>
              <td colSpan={14}>
                <Error message={state.errorMessage} />
              </td>
            </tr>
          )}
          {isSuccessState &&
            state.conferences.conferences.map(conference => (
              <ConferencesItem
                key={conference.id}
                conference={conference}
                service={service}
                handleShowModal={handleShowModal}
                handleGoToConference={handleGoToConference(conference.id)}
              />
            ))}
        </tbody>
      </Table>
      {isSuccessState && state.conferences.totalCount > 10 && (
        <Pagination
          totalCount={isSuccessState ? state.conferences.totalCount : 0}
          pageIndex={service.pageIndex.value}
          pageSize={paginationService.pageSize.value}
          maxNumberOfPages={10}
          onChange={handlePageChange}
        />
      )}

      <SubjectState
        subject={service.isTerminating}
        render={isTerminating => (
          <Modal
            isOpen={isTerminating}
            isLoading={service.isLoading.value}
            icon={
              <div className='bg-red-200 rounded-full p-2'>
                <ExclamationTriangleIcon className='w-10 h-10 text-red-600' />
              </div>
            }
            closeModal={handleCloseModal}
            submitModal={handleTerminate}
            submitButtonText='Terminate Conference'
            closeButtonText='Cancel'
            color='danger'>
            <div className='flex flex-col'>
              <p className='text-lg font-semibold mb-0'>Terminate Conference</p>
              <p className='mb-0'>Are you sure you want to terminate this conference call?</p>
            </div>
          </Modal>
        )}
      />

      <SubjectState
        subject={service.isTerminated}
        render={isTerminated => (
          <Modal
            isOpen={isTerminated}
            submitButtonText='Close'
            closeModal={handleCloseTerminatedModal}
            submitModal={handleCloseTerminatedModal}
            icon={
              <div className='bg-green-200 rounded-full p-2'>
                <CheckCircleIcon className='w-10 h-10 text-green-600' />
              </div>
            }>
            <div className='flex flex-col'>
              <p className='text-lg font-semibold mb-0'>Terminate Conference</p>
              <p className='mb-0'>Conference terminated successfully.</p>
            </div>
          </Modal>
        )}
      />

      <SubjectState
        subject={service.terminateConferenceMessage}
        render={message => {
          return (
            <Modal
              isOpen={!!message}
              submitButtonText='Close'
              submitModal={handleCloseErrorModal}
              icon={
                <div className='bg-red-200 rounded-full p-2'>
                  <ExclamationCircleIcon className='w-10 h-10 text-red-600' />
                </div>
              }>
              <div className='flex flex-col'>
                <p className='text-lg font-semibold mb-0'>There was an error trying to terminate this conference.</p>
                <p className='mb-0'>{message}</p>
              </div>
            </Modal>
          );
        }}
      />
    </>
  );
};
